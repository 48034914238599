import { ExternalLink, Heading } from "@ksoc-private/ui-core";
import type {
  ErrorBrowserLocationChangeRequired,
  LoginFlow,
  UpdateLoginFlowBody,
} from "@ory/client";
import type { MetaFunction } from "@remix-run/cloudflare";
import { json, redirect } from "@remix-run/cloudflare";
import { Link, useActionData, useLoaderData } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import {
  AuthenticationForm,
  AuthenticationFormWrapper,
} from "~/components/AuthenticationForm";
import { FlowError } from "~/components/kratos/FlowError";
import { FlowUi } from "~/components/kratos/FlowUi";
import { AuthUserError, createKratosClient } from "~/services/kratos.server";
import { getSession } from "~/services/session.server";
import { convertHeaderTypes } from "~/util/convertHeaderTypes";
import { notEnterpriseSSOProvider } from "~/util/enterpriseSSO";
import { isAxiosError } from "~/util/isAxiosError";
import type { ActionArgs, LoaderArgs } from "~/util/types";

export const handle = { i18n: ["auth"] };

export const meta: MetaFunction = () => {
  return [{ title: "Kubernetes Security Operations Center - Sign in" }];
};

export async function loader({ request, context }: LoaderArgs) {
  const kratos = createKratosClient(context);
  const session = await getSession(request, context.env.SESSION_SIGNING_KEY);

  try {
    const { data: flow, headers } = await kratos.createBrowserLoginFlow({
      refresh: true,
      cookie: request.headers.get("cookie")!,
    });
    session.set("flowId", flow.id);

    const url = new URL(request.url);
    if (url.searchParams.has("redirect")) {
      const { pathname, search } = new URL(
        url.searchParams.get("redirect")!,
        request.url,
      );
      session.set("redirectAfterLogin", pathname + search);
    }

    return session.commitWithResponse(
      json({ ...flow }, { headers: convertHeaderTypes(headers) }),
    );
  } catch (error) {
    if (!isAxiosError<LoginFlow>(error)) throw error;
    const { data, headers } = error.response ?? {};
    if (typeof data === "string") throw new AuthUserError(error);
    return session.commitWithResponse(
      json({ ...data }, { headers: convertHeaderTypes(headers) }),
    );
  }
}

export async function action({ request, context }: ActionArgs) {
  const kratos = createKratosClient(context);
  const session = await getSession(request, context.env.SESSION_SIGNING_KEY);
  const flowId = session.get("flowId") as string;

  if (!flowId) {
    //  They must have completed signing in, lets just refresh and handle it on the other side
    return redirect("/", { status: 302 });
  }

  const formData = await request.formData();

  try {
    const { headers } = await kratos.updateLoginFlow({
      flow: flowId,
      updateLoginFlowBody: Object.fromEntries(
        formData,
      ) as unknown as UpdateLoginFlowBody,
      cookie: request.headers.get("cookie")!,
    });
    session.unset("flowId");

    let redirectUrl = "/";
    if (session.has("redirectAfterLogin")) {
      redirectUrl = session.get("redirectAfterLogin")!;
      session.unset("redirectAfterLogin");
    }

    return session.commitWithResponse(
      redirect(redirectUrl, { headers: convertHeaderTypes(headers) }),
    );
  } catch (error) {
    if (!isAxiosError<LoginFlow | ErrorBrowserLocationChangeRequired>(error)) {
      throw error;
    }
    const { data, headers } = error.response ?? {};
    if (data) if (typeof data === "string") throw new AuthUserError(error);
    if (data && "redirect_browser_to" in data && data.redirect_browser_to) {
      const oidcUrl = new URL(data.redirect_browser_to);

      return session.commitWithResponse(
        redirect(oidcUrl.toString(), {
          headers: convertHeaderTypes(headers),
        }),
      );
    }
    return session.commitWithResponse(
      json(data, { headers: convertHeaderTypes(headers) }),
    );
  }
}

export default function SignIn() {
  const { t } = useTranslation("auth");
  const loaderData = useLoaderData<typeof loader>();
  const actionData = useActionData<typeof action>();
  const flow =
    "error" in loaderData
      ? { ...actionData, ...loaderData }
      : (actionData ?? loaderData);

  return (
    <AuthenticationFormWrapper>
      <AuthenticationForm data-test-id="sign-in-form">
        <Heading as="h2" size="xl" className="tw-text-center">
          {t("signIn.title")}
        </Heading>
        {"error" in flow && <FlowError error={flow.error!} />}
        {"ui" in flow && (
          <FlowUi
            groupOrder={["default", "password", "oidc"]}
            filterNodes={notEnterpriseSSOProvider}
            shouldHideNodeFromDefaultGroup={(nodeGroup, node) =>
              nodeGroup === "oidc" &&
              "name" in node.attributes &&
              node.attributes.name === "identifier"
            }
            {...(flow as LoginFlow)}
          />
        )}
        <p className="tw-mt-4 tw-text-sm tw-text-center tw-mb-0">
          <Link
            to="/recovery"
            className="tw-text-cyan-600 hover:tw-text-cyan-500"
          >
            {t("signIn.forgotPasswordLink")}
          </Link>
        </p>
        <p className="tw-mt-4 tw-text-sm tw-text-center tw-mb-0">
          <Link
            to="/enterprise-sso"
            className="tw-text-cyan-600 hover:tw-text-cyan-500"
          >
            {t("signIn.enterpriseSSOLink")}
          </Link>
        </p>
      </AuthenticationForm>
      <MSAAgreement />
    </AuthenticationFormWrapper>
  );
}

export function MSAAgreement() {
  return (
    <p className="tw-text-zinc-500 tw-mx-auto tw-items-center tw-text-xs tw-text-center tw-mt-4 tw-max-w-sm tw-text-pretty">
      By signing in you agree that usage of the RAD Security platform is
      governed by the terms and conditions of the Master Services Agreement
      executed between RAD Security and Customer (The "Agreement") located at{" "}
      <ExternalLink href="https://rad.security/msa">
        https://rad.security/msa
      </ExternalLink>{" "}
      or in the mutually negotiated MSA contemporaneously signed by both parties
    </p>
  );
}
